/*
MOBILE SIZES
	max-width: 1150px --  home page content stacked
	max-width: 900px  --  % width
												ideas content stacked
	max-width: 660px  --  stacked footer
												main content margins/padding
												2 idea thumbnails per line
	max-width: 510px  --  tall footer
*/
footer {
  width: 100%;
  max-width: 784px;
  margin: auto auto 0 auto;
  background-color: #404040;
  color: #b0b0b0;
}
footer ul {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 1em;
  justify-content: center;
}
footer li {
  margin: 0.5em;
  padding: 0 0.5em;
  text-align: center;
}
footer a.footer-link {
  color: inherit;
  text-decoration: none;
}
footer a:hover.footer-link {
  color: #509ab3;
}
footer .avoidwrap {
  display: inline-block;
}
header {
  padding: 0;
  text-align: left;
  background-color: #404040;
}
header h1 {
  margin: 0;
  font-size: 3em;
  font-weight: 800;
  color: #2e732e;
  text-transform: uppercase;
  font-family: Pangolin, 'Comic Sans MS', cursive;
}
@media only screen and (min-height: 62rem) and (max-height: 64rem) {
  header {
    padding: calc((100vh - 62rem) / 2) 0;
  }
}
@media only screen and (min-height: 64rem) {
  header {
    padding: 1rem 0;
  }
}
.navbar {
  width: 100%;
  background-color: #909090;
}
.navbar ul {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  max-width: 784px;
  margin: auto;
}
.navbar li {
  margin: 0;
  padding: 0.5em;
  user-select: none;
}
.navbar li:hover {
  background-color: #bfa556;
}
.navbar li.split {
  width: 2px;
  padding: 0px;
  background-color: #404040;
}
.navbar li.space {
  flex: 1;
}
.navbar li.space:hover {
  background-color: inherit;
}
.navbar a.nav-link {
  color: #282828;
  text-decoration: none;
}
.playful .navbar {
  font-family: Pangolin, 'Comic Sans MS', cursive;
}
.error-info a {
  color: #bfa556;
  text-decoration: none;
}
.error-info a:hover {
  color: #509ab3;
}
.page-content-outer {
  width: 100%;
  max-width: calc(784px + 1rem);
  margin: 0 auto;
}
.page-content-outer .page-content-inner {
  margin: 0 0.5rem;
}
.page-content-outer.flush {
  max-width: calc(784px);
}
.page-content-outer.flush .page-content-inner {
  margin: 0;
}
.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main {
  background-color: #404040;
  color: #d0d0d0;
}
img,
.unselectable {
  user-select: none;
}
li {
  list-style-type: none;
}
.business-info {
  text-align: left;
  padding: 0 0.5em;
  max-width: 49rem;
  margin: auto;
}
.business-info main ul {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 1em 0;
  justify-content: space-evenly;
}
.business-info a {
  color: #bfa556;
  text-decoration: none;
}
.business-info a:hover {
  color: #509ab3;
}
.business-info section.fade {
  color: #606060;
}
.business-info section.fade a {
  color: #808080;
}
.business-info section.fade a:hover {
  color: #509ab3;
}
.business-info section.fade a.button-link li {
  background-color: #606060;
  color: #808080;
}
.business-info section.fade a.button-link li:hover {
  background-color: #bfa556;
  color: #e0e0e0;
}
.privacy-policy {
  text-align: left;
  padding: 0 0.5em;
  max-width: 49rem;
  margin: auto;
}
.privacy-policy main {
  padding: 1em;
  color: #000;
  background-color: #fff;
  font-family: 'Times New Roman', Times, serif;
}
.privacy-policy main li {
  list-style-type: disc;
}
.home-page {
  padding: 0.5em 0;
}
section {
  margin-bottom: 2em;
}
p {
  text-align: left;
}
p:first-of-type {
  margin-top: 0;
}
p:last-of-type {
  margin-bottom: 0;
}
.home-page ul {
  max-width: calc(784px * 0.8);
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 1em 0;
  justify-content: space-evenly;
}
a.button-link {
  display: inline;
  list-style-type: none;
  text-decoration: none;
}
a.button-link li {
  padding: 1em;
  background-color: #1f5c99;
  color: #e0e0e0;
}
a.button-link li:hover {
  background-color: #bfa556;
}
.slideshow-outer {
  display: flex;
  position: relative;
  margin-bottom: 1.25em;
}
.slideshow-outer img {
  display: inline;
  position: relative;
  width: 100%;
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}
.slideshow-outer picture:not(:first-child) img {
  position: absolute;
  left: 0;
}
.slideshow-outer img.fade {
  opacity: 1;
}
.slideshow-outer img.active {
  opacity: 1;
}
.slideshow-outer p.error {
  font-style: italic;
}
.section-container {
  padding-top: 0.5em;
}
.section-title {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #1f5c99;
}
.section-title span {
  font-weight: 700;
  font-size: 1.2em;
}
.section-title:hover {
  background-color: #bfa556;
}
.section-title-text {
  text-align: left;
}
.section-content-outer {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
.section-content {
  margin-top: 0.5em;
  background-color: #606060;
  color: #c0c0c0;
  text-align: left;
}
.section-content p {
  padding: 1em;
}
.topic {
  margin-top: 0.5em;
  padding: 1em;
  background-color: #606060;
}
.playful .guide-title {
  font-family: Pangolin, 'Comic Sans MS', cursive;
}
.reliable .guide-title {
  font-family: 'Averia Serif Libre', Cambria, Arial, sans-serif;
}
.topic-title {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.2em;
}
.topic-title-text {
  text-align: left;
}
.topic-content-outer {
  height: auto;
  overflow: hidden;
}
.topic-content {
  margin-top: 0.5em;
  color: #c0c0c0;
}
.ideas-desc {
  max-width: 784px;
  margin: auto;
  padding: 1em 0em;
  text-align: left;
}
.idea-container {
  max-width: 784px;
  margin: 0 auto;
}
.playful .idea-container .idea-title {
  font-family: Pangolin, 'Comic Sans MS', cursive;
}
.reliable .idea-container .idea-title {
  font-family: 'Averia Serif Libre', cursive;
}
.idea-upper-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin: 1.5em 0;
}
.idea-upper-container .idea-image-container,
.idea-upper-container .idea-text-container {
  flex: 1;
}
.idea-upper-container .idea-image-container {
  display: flex;
}
.idea-upper-container .idea-image-container picture {
  display: flex;
}
.idea-upper-container .idea-image {
  width: 100%;
}
.idea-upper-container .idea-text-container {
  margin-left: 0.5em;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.idea-upper-container .idea-title {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0 1em 0;
  font-size: 1.2em;
  padding: 1em 0.5em;
  background-color: #1f5c99;
}
.idea-upper-container .idea-desc {
  flex: 1;
  margin: 0.5em 0 0 0;
  text-align: left;
  padding: 0.5em;
  background-color: #606060;
  color: #c0c0c0;
}
.idea-upper-container .idea-desc p {
  padding: 0.5em 0;
}
.idea-text-container .idea-desc {
  display: block;
}
.idea-followup-container .idea-desc {
  display: none;
}
.idea-title-desc-separator {
  height: 1px;
  width: 100%;
  background-color: #282828;
  margin: 0 auto;
}
.idea-lower-container {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
.idea-lower {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.5em 0 1.5em 0;
  gap: 1em;
}
.idea-thumbnail-container {
  width: calc((100% - 3em) * 0.25);
}
.idea-thumbnail-container:hover {
  box-shadow: 0px 0px 6px 3px #fff;
}
.idea-thumbnail-container:hover .idea-thumbnail {
  box-shadow: none;
}
.idea-thumbnail {
  width: 100%;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
@media screen and (max-width: 627.2px) {
  .idea-upper-container {
    flex-direction: column;
    align-items: stretch;
  }
  .idea-upper-container .idea-image-container {
    width: 100%;
  }
  .idea-upper-container .idea-image-container picture {
    width: 100%;
  }
  .idea-upper-container .idea-text-container {
    width: 100%;
    margin-left: 0;
  }
  .idea-text-container .idea-desc {
    display: none;
  }
  .idea-followup-container .idea-desc {
    display: block;
  }
}
.idea-detail-image {
  width: 100%;
  margin-bottom: 1em;
  box-shadow: none;
}
.idea-detail-container {
  max-width: 784px;
  margin: 1em auto 0 auto;
}
.playful .idea-detail-container .idea-detail-title {
  font-family: Pangolin, 'Comic Sans MS', cursive;
}
.reliable .idea-detail-container .idea-detail-title {
  font-family: 'Averia Serif Libre', cursive;
}
.idea-detail-page .idea-detail-title {
  margin: 0.5em 0;
  padding: 0.5em;
  background-color: #1f5c99;
}
.idea-detail-page .idea-detail-title h3 {
  margin: 0.5em 0;
}
.idea-detail-page .idea-detail-desc {
  margin: 0.5em 0;
  padding: 0.5em;
  background-color: #606060;
  color: #c0c0c0;
}
.idea-detail-page .idea-detail-desc p {
  margin: 0.5em 0;
}
.idea-detail-lower-container {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
.idea-detail-lower {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.5em 0 1.5em 0;
  gap: 1em;
}
.idea-detail-thumbnail-container {
  width: 100%;
}
.idea-detail-thumbnail-container picture {
  display: flex;
}
.idea-detail-container {
  display: none;
}
@media only screen and (min-width: 49rem) and (min-height: 57rem) {
  .idea-detail-slides {
    display: none;
  }
  .idea-detail-container {
    display: block;
  }
  .idea-detail-thumbnail-container {
    width: calc((100% - 3em) / 4);
  }
  img.idea-detail-image {
    display: block;
  }
}
.idea-detail-thumbnail-container:hover {
  box-shadow: 0px 0px 6px 3px #fff;
}
.idea-detail-thumbnail-container:hover .idea-detail-thumbnail {
  box-shadow: none;
}
.idea-detail-thumbnail {
  width: 100%;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
.support-page {
  padding: 0.5em 0;
}
.support-page a {
  color: #bfa556;
  text-decoration: none;
}
.support-page a:hover {
  color: #509ab3;
}
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html {
  overflow-y: scroll;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  background-color: #404040;
  font-family: Lexend, Verdana, Tahoma, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.unselectable {
  user-select: none;
}
