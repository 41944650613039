
.support-page {
	padding: 0.5em 0;
}

.support-page a {
	color: @sun;
	text-decoration: none;
}

.support-page a:hover {
	color: @sky;
}
