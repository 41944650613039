
.slideshow-outer {
	display:flex;
	position: relative;
	margin-bottom: 1.25em;
//	box-shadow: 2px 2px 4px 2px @coal;

	img {
		display: inline;
		position: relative;
		width: 100%;
		opacity: 0;
		transition: opacity 2000ms ease-in-out;
	}
	picture:not(:first-child) img {
		position: absolute;
		left: 0;
	}

	img.fade {
		opacity: 1;
	}
	img.active {
		opacity: 1;
	}

	p.error {
		font-style: italic;
	}
}
