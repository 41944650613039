
footer {
	width: 100%;
	max-width: @page-width;
	margin: auto auto 0 auto;

	background-color: @iron;
	color: @smoke;

	ul {
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		padding: 1em;
		justify-content: center;
	}
	
	li {
		margin: 0.5em;
		padding: 0 0.5em;
		text-align: center; // centers multi-line copyright text at very small page width
	}

	a.footer-link {
		color: inherit;
		text-decoration: none;
	}
	a:hover.footer-link {
		color: @sky;
	}

	.avoidwrap {
		display: inline-block;
	}
}
