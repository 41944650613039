
.page-content-outer {
	width: 100%;
	max-width: calc(@page-width + 1rem);
	margin: 0 auto;

	.page-content-inner {
		margin: 0 0.5rem;
	}
}

.page-content-outer.flush {
	max-width: calc(@page-width);
	.page-content-inner {
		margin: 0;
	}
}
