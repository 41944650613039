// @image-width:  24rem;
// @page-width: 49rem;
//@min-width: @image-width * 1.5;

.idea-container {
	max-width: @page-width;
	// margin: 1em auto 0 auto;
	margin: 0 auto;
}

.idea-container:hover {
//	background-color: @sun;
}

.playful .idea-container .idea-title {
	font-family: Pangolin, 'Comic Sans MS', cursive;
}

.reliable .idea-container .idea-title {
	font-family: 'Averia Serif Libre', cursive;
}

.idea-upper-container {
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
//	padding: 1.5em 0;
	margin: 1.5em 0;
//	gap: 1em;

	.idea-image-container,
	.idea-text-container {
		flex: 1;
	}
	.idea-image-container {
//		margin-right: 0.5em;
		display: flex;
	}
	.idea-image-container picture {
		display: flex;
	}
	.idea-image {
//		max-width: @image-width;
		width: 100%;
	}
	.idea-text-container {
		margin-left: 0.5em;
		align-self: stretch;
		display: flex;
		flex-direction: column;
	}

	.idea-title {
		display: flex;
//		width: 100%;
		justify-content: space-between;
		// padding: 0.5em 1em 1em 1em;
		padding: 0.5em 0 1em 0;
		font-size: 1.2em;

//max-width: @page-width;
//margin-left: 0.5em;
//margin-right: 0.5em;
//padding: 0.5em 0.5em 1em 0.5em;
//margin: 0.5em 0;
//margin: 0 0 0 0.5em;
padding: 1em 0.5em;
background-color: @ocean;
	}

	// .idea-title-desc-separator {
	// 	height: 1px;
	// 	width: 95%;
	// 	margin: auto;
	// 	background-color: @coal;
	// 	margin: 0.5em auto;
	// }
	
	.idea-desc {
		flex:1;
//		margin: 0.5em auto;
		margin: 0.5em 0 0 0;
		text-align: left;

		p {
			// padding: 1em 1em 0em 1em;
//			padding: 1em 0 0em 0;
padding: 0.5em 0;
		}

//		max-width: @page-width;
//margin: 0.5em 0.5em;
//		padding: 1em 0em;
//		text-align: left;
padding: 0.5em;
//background-color: @chalk;
background-color: #606060;
//color: #b0b0b0;
color: #c0c0c0;

	}
}

.idea-text-container .idea-desc {
	display: block;
}
.idea-followup-container .idea-desc {
	display: none;
}

.idea-title-desc-separator {
	height: 1px;
	// width: 95%;
	width: 100%;
	background-color: @coal;
//	margin: 0.5em auto;
	margin: 0 auto;
}

.idea-lower-container {
	height: 0;
	overflow: hidden;
	transition: height 0.5s ease-in-out;
}

.idea-lower {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0.5em 0 1.5em 0;
	gap: 1em;
}

.idea-thumbnail-container {
	width: calc((100% - 3em) * 0.25);
}

.idea-thumbnail-container:hover {
	box-shadow: 0px 0px 6px 3px #fff;

	.idea-thumbnail {
		box-shadow: none;
	}
}

.idea-thumbnail {
	width: 100%;
	overflow: hidden;
	transition: height 0.5s ease-in-out;
}

// img {
// 	box-shadow: 2px 2px 4px 2px @coal;
// }

// @media screen and (max-width: @image-width * 1.5) {
// @media screen and (max-width: @page-width * 0.75) {
@media screen and (max-width: @page-width * 0.8) {
	.idea-upper-container {
//		flex-direction: column-reverse;
		flex-direction: column;
		align-items: stretch;

		.idea-image-container {
//			margin: 0 -0.5rem;
//			width: 100vw;
			width: 100%;
		}
		.idea-image-container picture {
			width: 100%;
		}
		.idea-text-container {
			width: 100%;

			margin-left: 0;
			// align-self: stretch;
			// display: flex;
			// flex-direction: column;
		}
	}

	// .idea-image-container {
	// 	margin-right: 0.5em;
	// }
	// .idea-text-container {
	// 	margin-left: 1em;
	// }
	.idea-text-container .idea-desc {
		display: none;
	}
	.idea-followup-container .idea-desc {
		display: block;
	}
}

// @media only screen and (max-width: 660px) {
// 	.idea-lower {
// 		max-width: calc(@image-width + 3em);
// 		margin: auto;
// 	}

// 	.idea-thumbnail-container {
// 		width: calc((100% - 1em) * 0.5);
// 	}
// }

