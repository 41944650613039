
header {
	padding: 0;
	text-align: left;
	background-color: @iron;

	h1 {
		margin: 0;
		font-size: 3em;
		font-weight: 800;
		color: @fern;
		text-transform: uppercase;
		font-family: Pangolin, 'Comic Sans MS', cursive;
	}
}

@media only screen and (min-height: 62rem) and (max-height: 64rem) {
	header {
		// padding: 3.125% 0;
		padding: calc((100vh - 62rem) / 2) 0;
	}
}

@media only screen and (min-height: 64rem) {
	header {
//		padding: 1rem 0 0.5rem 0;
		padding: 1rem 0;

		h1 {
//			margin: 0.25em 0;
		}
	}
}
