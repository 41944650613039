
/*
MOBILE SIZES
	max-width: 1150px --  home page content stacked
	max-width: 900px  --  % width
												ideas content stacked
	max-width: 660px  --  stacked footer
												main content margins/padding
												2 idea thumbnails per line
	max-width: 510px  --  tall footer
*/

@import "site/footer.less";
@import "site/header.less";
@import "site/nav-bar.less";
@import "site/not-found.less";
@import "site/page-content.less";
@import "site/page-layout.less";
@import "business/business.less";
@import "business/privacy.less";
@import "home/home.less";
@import "home/slideshow.less";
@import "guide/section.less";
@import "guide/topic.less";
@import "ideas/ideas.less";
//@import "ideas/idea.less";
@import "ideas/idea-summary.less";
@import "ideas/idea-detail.less";
@import "support/support.less";

@white: #ffffff;
@chalk: #f0f0f0;
@fog: #e0e0e0;
@ash: #d0d0d0;
@smoke: #b0b0b0;
@stone: #909090;
@iron: #404040;
@coal: #282828;
@obsidian: #181818;
@black: #000000;

@earth: #734b22;
@sun: #bfa556;
@fern: #2e732e;
@sky: #509ab3;
@ocean: #1f5c99;

@image-width: 384px;
@page-width: 784px;

//@image-width: 472px;
// @image-width: 512px;
// @page-width: calc(@image-width * 2 + 4em);
// @page-width: calc(@image-width * 2 + 1em);

// @image-width: 24rem;
// @page-width: 49rem;
// //@image-height: @image-width * 9 / 16;
// @image-height: 13.5rem;



* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

html {
	overflow-y: scroll;
}

body {
	position: relative;
	width: 100%;
	height: 100%;
}

body {
	margin: 0;
	background-color: @iron;
	font-family: Lexend, Verdana, Tahoma, Arial, sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.unselectable {
	user-select: none;
}