
.business-info {
	text-align: left;
	padding: 0 0.5em;
	max-width: 49rem;
	margin: auto;
}

.business-info main ul {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	padding: 1em 0;
	justify-content: space-evenly;
}

.business-info a {
	color: @sun;
	text-decoration: none;
}

.business-info a:hover {
	color: @sky;
}

.business-info section.fade {
	color: #606060;

	a {
		color: #808080;
	}

	a:hover {
		color: @sky;
	}
}

.business-info section.fade a.button-link {
	li {
		background-color: #606060;
		color: #808080;
	}

	li:hover {
		background-color: @sun;
		color: @fog;
	}
}
