
.navbar {

	width: 100%;
	background-color: @stone;

	ul {
		display: flex;
		justify-content: flex-start;
		padding: 0;
		max-width: @page-width;
		margin: auto;
	}

	li {
		margin: 0;
		padding: 0.5em;
		user-select: none;
	}

	li:hover {
		background-color: @sun;
	}

	li.split {
		width: 2px;
		padding: 0px;
		background-color: @iron;
	}

	li.space {
		flex: 1;
	}

	li.space:hover {
		background-color: inherit;
	}

	a.nav-link {
		color: @coal;
		text-decoration: none;
	}
}

.playful .navbar {
	font-family: Pangolin, 'Comic Sans MS', cursive;
}
