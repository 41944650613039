
.home-page {
	padding: 0.5em 0;
}

section {
	margin-bottom: 2em;
}

p {
	text-align: left;
}

p:first-of-type {
	margin-top: 0;
}

p:last-of-type {
	margin-bottom: 0;
}

.home-page ul {
	max-width: calc(@page-width * 0.8);
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	padding: 1em 0;
	justify-content: space-evenly;
}

a.button-link {
	display: inline;
	list-style-type: none;
	text-decoration: none;

	li {
		padding: 1em;
		background-color: @ocean;
		color: @fog;
	}
	
	li:hover {
		background-color: @sun;
	}
}

