
.topic {
	margin-top: 0.5em;
	padding: 1em;
	background-color: #606060;
}

.playful .guide-title {
	font-family: Pangolin, 'Comic Sans MS', cursive;
}

.reliable .guide-title {
	font-family: 'Averia Serif Libre', Cambria, Arial, sans-serif;
}


.topic-title {
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	font-size: 1.2em;
}

.topic-title-text {
	text-align: left; // if too narrow for single line, stay to left side of title area
}

.topic-content-outer {
	height: auto;
	overflow: hidden;
}

.topic-content {
	margin-top: 0.5em;
//	color:#b0b0b0;
	color:#c0c0c0;
}
