
.section-container {
	padding-top: 0.5em;
}

.section-title {
	display: flex;
	justify-content: space-between;
	padding: 1em;

	background-color: @ocean;

	span {
		font-weight: 700;
		font-size: 1.2em;
	}

	&:hover {
		background-color: @sun;
	}
}

.section-title-text {
	text-align: left; // if too narrow for single line, stay to left side of title area
}

.section-content-outer {
	height: 0;
	overflow: hidden;
	transition: height 0.5s ease-in-out;
}

.section-content {
	margin-top: 0.5em;
	background-color: #606060;
//	color:#b0b0b0;
	color:#c0c0c0;
	text-align: left;

	p {
		padding: 1em;
	}
}
