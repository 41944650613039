
.privacy-policy {
	text-align: left;
	padding: 0 0.5em;
	max-width: 49rem;
	margin: auto;

	main {
		padding: 1em;
		color: #000;
		background-color: #fff;
		font-family:'Times New Roman', Times, serif;

		li {
			list-style-type:disc;
		}
	}
}

// .privacy-policy ul {
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin: auto;
// 	padding: 1em 0;
// 	justify-content: space-evenly;
// }

