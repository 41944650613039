
.page-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	background-color: @iron;
	color: @ash;
}

img,
.unselectable {
	user-select: none;
}

li {
	list-style-type: none;
}
