

.idea-detail-page {
}

.idea-detail-image {
	width: 100%;
	margin-bottom: 1em;
	box-shadow: none;
}

.idea-detail-container {
	max-width: @page-width;
	margin: 1em auto 0 auto;
}

// .idea-detail-container:hover {
// //	background-color: @sun;
// }

.playful .idea-detail-container .idea-detail-title {
	font-family: Pangolin, 'Comic Sans MS', cursive;
}

.reliable .idea-detail-container .idea-detail-title {
	font-family: 'Averia Serif Libre', cursive;
}


.idea-detail-page .idea-detail-title {
//	margin: 0.5em -0.5em;
	margin: 0.5em 0;
	padding: 0.5em;
	background-color: @ocean;
	h3 {
		margin: 0.5em 0;
	}
}
.idea-detail-page .idea-detail-desc {
//	margin: 0.5em -0.5em;
	margin: 0.5em 0;
	padding: 0.5em;
	background-color: #606060;

	color: #c0c0c0;

	p {
		margin: 0.5em 0;
	}
}
		

// .idea-detail-upper-container {
// 	display: flex;
// 	align-items: flex-start;
// 	padding: 1.5em 0;
// 	gap: 1em;

// 	.idea-detail-image-container,
// 	.idea-detail-text-container {
// 		flex: 1;
// 	}

// 	.idea-detail-image {
// 		max-width: @image-width;
// 		width: 100%;
// 	}

// 	.idea-detail-title {
// 		display: flex;
// 		width: 100%;
// 		justify-content: space-between;
// 		padding: 0.5em 1em 1em 1em;
// 		font-size: 1.2em;
// 	}

// 	.idea-detail-title-desc-separator {
// 		height: 1px;
// 		width: 95%;
// 		margin: auto;
// 		background-color: @coal;
// 		margin: 0.5em auto;
// 	}
	
// 	.idea-detail-desc {
// 		margin: 0.5em auto;
// 		text-align: left;
		
// 		p {
// 			padding: 1em 1em 0em 1em;
// 		}
// 	}
// }

.idea-detail-lower-container {
//	height: 0;
	overflow: hidden;
	transition: height 0.5s ease-in-out;
}

.idea-detail-lower {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0.5em 0 1.5em 0;
	gap: 1em;
}

.idea-detail-thumbnail-container {
	width: 100%;
	picture {
		display: flex;
	}
}
.idea-detail-image {
//	display:none;
}

// ** using 100% width here means @image-height does not reflect actual height of displayed image
// @media only screen and (min-width: @image-width) and (min-height: calc(@image-height * 4)) {

// @media only screen and (min-width: @image-width) and (max-aspect-ratio: 4 / 9) {
// 	.idea-detail-thumbnail-container {
// 		width: calc((100% - 1em) / 2);
// 	}
// 	.idea-detail-image {
// 		display:block;
// 	}
// }

// // h >= w * 9 / 16 * 3
// // @media only screen and (min-width: @image-width * 2) and (max-aspect-ratio: 16 / 27) {
// @media only screen and (min-width: @image-width * 2) and (max-aspect-ratio: 32 / 45) {
// 	.idea-detail-thumbnail-container {
// 		width: calc((100% - 2em) / 3);
// 	}
// 	.idea-detail-image {
// 		display:block;
// 	}
// }

// // ** once max-width of @page-width kicks in, aspect ratio calculation is incorrect
// //
// // h >= w * 9 / 16 * 5 / 2
// @media only screen and (min-width: @image-width * 3) and (max-aspect-ratio: 32 / 45) {
// //@media only screen and (min-width: @image-width * 3) {
// 	.idea-detail-thumbnail-container {
// 		width: calc((100% - 3em) / 4);
// 	}
// 	.idea-detail-image {
// 		display:block;
// 	}
// }

.idea-detail-container {
	display: none;
}

// @image-width: 24rem;
// @page-width: 49rem;
//@media only screen and (min-width: @page-width) and (min-height: @page-width * 1.3) {

@media only screen and (min-width: 49rem) and (min-height: 57rem) {
	.idea-detail-slides {
		display: none;
	}
	.idea-detail-container {
		display: block;
	}

	.idea-detail-thumbnail-container {
		width: calc((100% - 3em) / 4);
	}

	img.idea-detail-image {
		display:block;
//		box-shadow: none;
	}

	// .idea-detail-image {
	// 	box-shadow: 2px 2px 4px 2px @coal;
	// }

}
	

.idea-detail-thumbnail-container:hover {
	box-shadow: 0px 0px 6px 3px #fff;

	.idea-detail-thumbnail {
		box-shadow: none;
	}
}

.idea-detail-thumbnail {
	width: 100%;
	overflow: hidden;
	transition: height 0.5s ease-in-out;
}

// img {
// 	box-shadow: 2px 2px 4px 2px @coal;
// }



// @media only screen and (max-width: @image-width) {
// 	.idea-detail-lower-container {
// 		margin: 0 -0.5rem;
// 	}
// 	.idea-detail-thumbnail {
// 		box-shadow: none;
// 	}
// 	.idea-detail-thumbnail-container {
// 		box-shadow: none;
// 	}
// 	.idea-detail-thumbnail-container:hover {
// 		box-shadow: none;
// 	}
// 	.idea-detail-slides img {
// 		box-shadow: none;
// 	}
// }

